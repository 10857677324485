@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply font-sans;
}
@layer base {
  :root {
    --radius: 0.5rem;

    --secondaryBrand: #1f62f2;
    --brand: 243 67% 22%; /* blue */
    --critical: 0 84.2% 60.2%; /* red */
    --white: #ffffff;
    --goodLight: #dcfce7;
    --good: #49950d;
    /* light2 color Check later */
    --light2: 240 18% 69%; /* original light gray color was this: 214.3 31.8% 91.4% */
    --light3: 240 18% 69%; /* middle gray */
    --black: 222.2 84% 4.9%; /* black */

    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 241 43.8% 30%;
    --sidebar-accent-foreground: 0 0% 98%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
    --dark: 0 0% 100%;
    --dark-brown: 222.2 84% 4.9%;
    --custom: 222.2 47.4% 11.2%;
    --dark-purple: 210 40% 98%;
    --dark-purple-secondary: 210 40% 96.1%;
    --dark-red: 215.4 16.3% 46.9%;
    --destructive: 0 84.2% 60.2%;
    --basic-border-color: 214.3 31.8% 91.4%;
    --appLightBlue: 210 37% 97%;
    --dark-orange: 217.2 32.6% 17.5%;
    --destructive: 0 62.8% 30.6%;

    --sidebarBackground: 243 67% 22%;
    --sidebarText: 240 18% 69%;
    --appDarkOrange: 33 93% 54%;
    --appDarkGrey: 0 7% 66%;
    --appTotemPole: 0 90% 31%;
    --appMediumGray: 246 6% 67%;
    --appLightGray: 255 7% 79%;
    --appPurple900: 249 21% 20%;
    --appGray100: 220 14% 96%;
    --muted-foreground: 215 20.2% 65.1%;
    --ring: 212.7 26.8% 83.9%;

    --background: var(--dark);
    --foreground: var(--dark-brown);

    --card: var(--dark);
    --card-foreground: var(--dark-brown);

    --popover: var(--dark);
    --popover-foreground: var(--dark-brown);

    --primary: var(--custom);
    --primary-foreground: var(--dark-purple);

    --secondary: var(--dark-purple-secondary);
    --secondary-foreground: var(--custom);

    --muted: var(--dark-purple-secondary);
    --muted-foreground: var(--dark-red);

    --accent: var(--dark-purple-secondary);
    --accent-foreground: var(--custom);

    --destructive-foreground: var(--dark-purple);

    --border: var(--basic-border-color);
    --input: var(--basic-border-color);
    --ring: var(--dark-brown);

    --appBackground: var(--appLightBlue);
  }

  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
    --background: var(--dark-brown);
    --foreground: var(--dark-purple);

    --card: var(--dark-brown);
    --card-foreground: var(--dark-purple);

    --popover: var(--dark-brown);
    --popover-foreground: var(--dark-purple);

    --primary: var(--dark-purple);
    --primary-foreground: var(--custom);

    --secondary: var(--dark-orange);
    --secondary-foreground: var(--dark-purple);

    --muted: var(--dark-orange);

    --accent: var(--dark-orange);
    --accent-foreground: var(--dark-purple);

    --destructive-foreground: var(--dark-purple);

    --secondaryDestructive: var(--destructive);
    --secondaryDestructive-foreground: var(--dark-purple);

    --border: var(--dark-orange);
    --input: var(--dark-orange);
  }
}

@layer base {
  * {
    @apply border-border;
  }
}

@layer components {
  *::-webkit-scrollbar,
  body::-webkit-scrollbar,
  .ps-sidebar-container::-webkit-scrollbar {
    width: 4px; /* Adjust the width of the scrollbar */
    height: 4px; /* Adjust the height of the scrollbar */
  }
  *::-webkit-scrollbar-track,
  body::-webkit-scrollbar-track,
  .ps-sidebar-container::-webkit-scrollbar-track {
    @apply rounded bg-gray-200;
  }
  *::-webkit-scrollbar-thumb,
  body::-webkit-scrollbar-thumb,
  .ps-sidebar-container::-webkit-scrollbar-thumb {
    @apply rounded bg-gray-500;
  }
  *::-webkit-scrollbar-thumb:hover,
  body::-webkit-scrollbar-thumb:hover,
  .ps-sidebar-container::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-700;
  }
}

/* Privacy page nested list items numbering in decimal points starts */
.privacy-page ol {
  counter-reset: item;
}
.privacy-page li {
  display: block;
}
.privacy-page ol > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

@keyframes collapse {
  from {
    height: var(--initial-height, auto);
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
}

.animate-collapse {
  animation: collapse 0.5s ease-out forwards;
}
